<template>
<!-- 网上发票信息 -->
    <el-form ref="forminfo" class="content bascform" :model="form" :class="{disabled:formDisabled}" :disabled="formDisabled" label-width="120px">
        <div  class="col col4">
            <el-form-item label="网上发票号">
                <el-input v-model="form.onlineInvoiceNumber"></el-input>
            </el-form-item>
        </div>
        <div  class="col col4">
            <el-form-item label="供应商">
                <span class="onlyText">
                    {{form.supplierCode+'-'+form.supplier}}
                </span>
            </el-form-item>
        </div>
        <div  class="col col4">
            <el-form-item label="公司名称">
                <span class="onlyText">
                {{form.corporateCode+'-'+form.corporateName}}
                </span>
                <!-- <el-input v-model="form.corporateName"></el-input> -->
            </el-form-item>
        </div>
        <div  class="col col4">
            <el-form-item label="开票方">
                <span class="onlyText">
                {{form.billingPartyCode+'-'+form.billingParty}}
                </span>
            </el-form-item>
        </div>
        <div class="col col4">
            <el-form-item label="是否寄售">
                <el-select v-model="form.consignmentOrNot" >
                    <el-option label="是" :value="0"></el-option>
                    <el-option label="否" :value="1"></el-option>
                </el-select>
            </el-form-item>
        </div>
        <div class="col col4">
            <el-form-item label="对账单类型">
                <el-select v-model="form.statementType" >
                    <el-option label="正常采购" :value="0"></el-option>
                    <el-option label="分期付款" :value="1"></el-option>
                </el-select>
            </el-form-item>
        </div>
        <div  class="col col4">
            <el-form-item label="币种">
                <el-input v-model="form.currency"></el-input>
            </el-form-item>
        </div>
        <div class="col col4">
            <el-form-item label="是否红字发票">
            <span class="onlyText">
                {{form.redInkMark=='X'?'是':'否'}}
            </span>
            </el-form-item>
        </div>
        <div  class="col col4">
            <el-form-item label="含税总额">
                <el-input v-model="form.totalIncludingTax"></el-input>
            </el-form-item>
        </div>
        <div class="col col4">
            <el-form-item label="付款状态">
            <el-select v-model="form.paymentStatus" >
                <el-option label="已付款" :value="0"></el-option>
                <el-option label="未付款" :value="1"></el-option>
                <el-option label="部分付款" :value="2"></el-option>
            </el-select>
            </el-form-item>
        </div>
        <div  class="col col4">
            <el-form-item label="未税总额">
                <el-input v-model="form.totalNotTaxed"></el-input>
            </el-form-item>
        </div>
        <div class="col col4">
            <el-form-item label="网上发票状态">
                <el-select v-model="form.onlineInvoiceStatus" >
                    <el-option label="待匹配发票" :value="0"></el-option>
                    <el-option label="已作废" :value="1"></el-option>
                    <el-option label="已完成" :value="2"></el-option>
                    <el-option label="取消记账" :value="3"></el-option>
                    <el-option label="记账失败" :value="4"></el-option>
                    <el-option label="待签收" :value="5"></el-option>
                    <el-option label="已签收" :value="6"></el-option>
                </el-select>
            </el-form-item>
        </div>
        <div  class="col col4">
            <el-form-item label="总税额">
                <el-input v-model="form.totalOnlineInvoiceTax"></el-input>
            </el-form-item>
        </div>
        <div  class="col col4">
            <el-form-item label="创建日期">
            <el-input v-model="form.createDate"></el-input>
            </el-form-item>
        </div>
    </el-form>
</template>

<script>
import Component from '@/assets/js/components.js'
import { requestForm } from '@/assets/js/http.js'
export default {
  name: 'OnlineInvoiceInfo',
  components: Component.components,
  data: function () {
    return {
      form: {},
      formDisabled: true
    }
  },
  mounted () {
    this.SupplierinvoiceDetail()
  },
  methods: {
    SupplierinvoiceDetail () {
      const id = this.$route.query.id
      requestForm('/api/reconciliation/invoiceTotal/queryOne?id=' + id, 'post').then((res) => {
        if (res.code === '200') {
          this.form = res.data
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/css/tableList.scss';
  .disabled {
  .col{
    margin: 3px 0 !important;
  }
}
</style>
